import React from "react";
import Navbar from "./Navbar";
import Fade from "react-reveal/Fade";
import MascoteQuacPay from "../Images/mascotequacpay2.png";
import styles from "./Header.module.css"; // Import the CSS module
function Header() {
  return (
    <div className="from-[#2B263F] to-[#231E3D] bg-gradient-to-b w-full  px-10 lg:px-28 lg:h-65vh">
      <Navbar className="z-50" />
      <div className="lg:flex lg:pt-8">
        <Fade left cascade>
          <div className="lg:w-[800px] container xl:pt-18 lg:pt-8 pt-16">
            <h1 className="font-[1000] xl:text-[4.0rem] lg:text-[3rem] md:text-[3.2rem] text-3xl lg:w-[79%] text-white xl:leading-[5rem] md:leading-[4rem]">
              Simplificando pagamentos com{" "}
              <span className="text-transparent bg-clip-text bg-gradient-to-br from-[#ff6f00] to-[#fa862d]">
                integração inteligente.
              </span>
            </h1>
            <div className="xl:flex justify-start mt-7">
              <div>
                <a
                  href="https://quacpay.com/dashboard/"
                  className="rounded px-7 py-3 bg-[#ff6f00] text-white relative group hover:text-white overflow-hidden c-btn tracking-wider"
                >
                  Conheça nossos beneficios
                </a>
              </div>
              <p className="xl:w-[50%] lg:w-[70%] md:w-[80%] text-[14px] text-gray-400 lg:leading-6 xl:pl-5 xl:pt-0 pt-4 pb-4">
                Torne-se um cliente QuacPay e simplifique a gestão financeira da sua empresa.
                Com nossa integração fácil, você tem mais controle, segurança e eficiência nos pagamentos,
                tudo em uma plataforma única e inovadora.
              </p>


            </div>
          </div>

        </Fade>

        <div className="pr-24 lg:pr-0 flex items-start lg:items-center">
          <Fade right cascade>
            <div className="boy-illustration lg:pt-18">
            <img
                src={MascoteQuacPay}
                alt="Mascote QuacPay"
                className={`${styles.mascote}`} // Apply the CSS class
              />
            </div>
          </Fade>
        </div>

      </div>
    </div>
  );
}

export default Header;
