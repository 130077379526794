import { MenuIcon, XIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import { Disclosure } from "@headlessui/react";
import { HashLink } from "react-router-hash-link";

const navigation = [
  { name: "Inicio", href: "/", current: true },
  { name: "Sobre nós", href: "#projects", current: false },
  { name: "Integração", href: "#services", current: false },
  { name: "Ajuda", href: "#about", current: false },
  { name: "Termos de Uso", href: "#about", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar({ className }) {
  const [navs, setNavs] = useState(navigation);

  const handleActive = (nav) => {
    navs.forEach((e) => (e.current = e.name === nav.name));
    setNavs([...navs]);
  };

  return (
    <Disclosure as="nav" className={`bg-transparent ${className}`}>
      {({ open }) => (
        <>
          <div className="max-w-7xl">
            <div className="relative flex flex-row-reverse lg:flex-row items-center justify-between h-16">
              <div className="inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="mx-auto ml-0 flex flex-start w-[80%] sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center pl-0">
                  <h1 className="font-bold text-white" style={{ fontSize: "1.5rem" }}>QuacPay</h1>
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="ml-40 flex justify-start space-x-8">
                    {navs.map((item) => (
                      <HashLink
                        onClick={() => handleActive(item)}
                        smooth
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "text-transparent bg-clip-text bg-gradient-to-br from-[#ff6f00] to-[#fa862d] text-xl lg:text-2xl" // Aumentei o texto aqui
                            : "text-white hover:text-[#ff6f00] hover:scale-110 text-lg lg:text-xl", // Aumentei o texto aqui
                          "px-3 py-2 rounded-md font-medium md:text-base lg:text-lg hover:scale-110 ease-in duration-200 whitespace-nowrap"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </HashLink>
                    ))}
                  </div>




                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 contact-btn">
                <a
                  href="https://quacpay.com/dashboard/"
                  className="text-white border rounded px-7 py-2 md:px-5 relative tracking-wider overflow-hidden transition-all duration-300"
                  style={{
                    borderColor: "#FFFFFF",
                  }}
                >
                  <span
                    className="absolute inset-0 flex justify-center items-center opacity-0 hover:opacity-100 transition-opacity duration-300"
                    style={{
                      backgroundColor: "#141414",
                    }}
                  ></span>
                  <span className="relative z-10" style={{ color: "#FFFFFF" }}>
                    Área do Cliente
                  </span>
                </a>
              </div>

            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navs.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  onClick={() => handleActive(item)}
                  className={classNames(
                    item.current
                      ? "bg-transparent text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block px-3 py-2 rounded-md text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
              <Disclosure.Button
                as="a"
                href="https://quacpay.com/dashboard"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
              >
                Área do Cliente
              </Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
